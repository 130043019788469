import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='social-icons'>
            <p>Powered by</p>
            <img class='' src='/images/saf.png'  alt='Safaricom' />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;