import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import './Navbar.css';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const naviget = useNavigate();
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false)
    } else {
      setButton(true)
    }
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener('resize', showButton);

  function logoutSubmit() {
    localStorage.setItem("login", false);
    localStorage.setItem("loginStatus", "logged out");	
    naviget("/sign-in");
  }

  return (
    <>
      <nav className='navbar'>
      <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img src='/images/skizalogo.png' alt='logo' />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
              <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
             <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Categories
              </Link>
              </li>
              <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Ranks
              </Link>
              </li>
              <li className='nav-item'>
              <Link to='/business' className='nav-links' onClick={closeMobileMenu}>
                Skiza for Business
              </Link>
              </li>             
              <li className='nav-item'>
              <Link to='/sign-in' className='nav-links-mobile' onClick={closeMobileMenu}>
                <i className="far fa-user"></i>
                Sign In
              </Link>
            </li>
          </ul>
          {button && <Button buttonStyle='btn--outline'>SIGN IN
          <i className="far fa-user"></i>
          </Button>}
          {button && <Button buttonStyle='btn--outline'>LOGOUT
          <i className="fa fa-sign-out" onClick={logoutSubmit}></i>
          </Button>}

      </div>
    </nav>
    </>
  )
}

export default Navbar